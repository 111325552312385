import React from 'react';
import '../App.css';
import { Typography } from 'antd';

const { Title } = Typography;

class ArticleDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      smallView: (window.innerWidth < 100)
    }
  }

  componentDidMount(){
    this.handleResize = this.handleResize.bind(this);
    window.addEventListener('resize', this.handleResize);    
  }

  handleResize() {
    if( (window.innerWidth < 1100) && (this.state.smallView == false)){
      this.setState({ smallView: true });
    } else if( (window.innerWidth >= 1100) && (this.state.smallView == true)){
      this.setState({ smallView: false });
    }
  }

  getPosition(value, ret1, ret2){
    if(value == true) return ret1;
    else return ret2;
  }

  render() {
    return (
      <div style={{width: "100%", display: 'flex', flexDirection: this.getPosition(this.props.pos, "row", "row-reverse"), alignItems: 'flex-start', marginBottom: '50px'}}>
        <div hidden={this.state.smallView} style={{flexGrow: 1, flexBasis: 0, textAlign: 'center', zIndex: 0}}>
            <a href={this.props.link}>
              <img src={this.props.img} style={{height: '85vh', padding: '15px', boxShadow: "5px 5px 9px grey"}}/>
            </a>
        </div>
        <div style={{flexGrow: 1, flexBasis: 0, padding: '15px', boxShadow: "5px 5px 9px grey"}}>
          <Title style={{fontSize: '2.2em'}}> {this.props.title} </Title>
          <div style={{fontSize: '1.7em', textAlign: 'justify', textJustify: 'inter-word'}}>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  };
}

export default ArticleDisplay;
