import React from 'react';

class TextContainer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{fontSize: '2em', marginLeft: '45px', marginRight: '45px', textAlign: 'justify', textJustify: 'inter-word', marginBottom: '50px'}}>
        {this.props.children}
      </div>
    );
  };
}

export default TextContainer;
