import React from 'react';
import '../App.css';
import { Typography } from 'antd';

const { Title } = Typography;

class ContactsPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: "row", boxShadow: "5px 5px 9px grey"  }}>
            <div style={{flexGrow: 1, flexBasis: 0, backgroundColor: "#f0f1f2", padding: "15px"}}> { /* #f8f9fa */ } 
                <Title style={{fontSize: '4em'}}> {this.props.title} </Title>
                {this.props.children}
            </div>
            <div style={{flexGrow: 1, flexBasis: 0, backgroundColor: "#f8f9fa"}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1020.4502263574241!2d22.48024420677568!3d51.234337918998726!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4722584f6d7be293%3A0x553170520ab8be6e!2sLazurowa%2026%2C%2020-781%20Lublin!5e0!3m2!1spl!2spl!4v1613580261783!5m2!1spl!2spl" width="100%" height="100%" style={{ border:0 }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
            </div>
        </div>
    )
  };
}

export default ContactsPanel;
