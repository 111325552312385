import React from 'react';
import 'antd/dist/antd.css';
import './App.css';
import { Layout, Menu, Typography } from 'antd';
import {Link} from 'react-scroll'
import TextContainer from './components/TextContainer.js';
import ArticleDisplay from './components/ArticleDisplay.js';
import ContactsPanel from './components/ContactsPanel.js';


import JEE from './images/JEE.jpg'
import EEET from './images/EEET.jpg'
import ASTRJ from './images/ASTRJ.jpg'

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

function App() {
  return (
    <Layout>
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%', backgroundColor: 'white'}}>
        <div className="logo" />
        <div style={{float: "left", margin: "0px 24px 0px 0px", color: "#254099", fontWeight: "normal"}}> Founded in 1991 </div>
        <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']} inlineIndent='10' style={{whiteSpace: "nowrap"}}>
          <Menu.Item key="1"><Link to="home" offset={-70} spy={true} smooth={true}> Home </Link></Menu.Item>
          <Menu.Item key="2"><Link to="About" offset={-50} spy={true} smooth={true}> About us </Link></Menu.Item>
          <Menu.Item key="3"><Link to="Publications" offset={-50} spy={true} smooth={true}> Publications </Link></Menu.Item>
          <Menu.Item key="4"><Link to="Contact" offset={-50} spy={true} smooth={true}> Contact </Link></Menu.Item>
        </Menu>
      </Header>
      <Content className="site-layout" style={{ padding: '0px', marginTop: 66 }} id="home">
        <div className="niceImage-background">
          <div className="mainText">
          Publish with WNGB Scientific Publishing House!
          </div>
        </div>
        <div className="site-layout-background" style={{ padding: 24, minHeight: 380 }}>
          <div id="About">
            <Title style={{ textAlign: 'center', fontFamily: 'roboto', fontWeight: 'bold', fontSize: '4.5em' }}>About us</Title>
            <TextContainer>
            &nbsp;&nbsp;&nbsp;&nbsp;For many years WNGB was mainly involved in technical preparation and publishing of scientific books in Technology and Ecology area. It focused on professional elaboration the hundreds of books published by renowned institutions in Poland, such as: Ministry of the Environment, Chief Inspectorate of Environmental Protection, Polish Academy of Sciences, Technical Universities as well National Research Institutes. From 1995 strictly cooperated with Institute of Environmental Protection in Warsaw and the Main Board of Polish Society of Ecological Engineering in Warsaw.
  From Dec. 1994 started to publishing the printed version of the scientific and technical journal entitled Ekoinżynieria (ISSN 1233-443X). To the March 1999, it was released 39 issues. From 2000 to 2012 was published in print Inżynieria Ekologiczna (ISSN 2081-139X), next from 2013 to 2020 in electronic version (ISSN 2392-0629), and from Dec. 2020 continuing as Ecological Engineering & Environmental Technology (ISSN 2719-7050). From 2007 to 2012 was published in print the scientific journal entitled Postępy Nauki i Techniki (ISSN 2080-4075), and from Dec. 2012 continuing in electronic version as Advances in Science and Technology Research Journal (ISSN 2299-8624).
  From 2013 published international electronic journal entitled Journal of Ecological Engineering (ISSN 2299-8993). At the present time, the main WNGB activity is the publishing of three international scientific journals in electronic version.
            </TextContainer>
          </div>
          <div id="Publications">
            <Title style={{ textAlign: 'center', fontFamily: 'roboto', fontWeight: 'bold', fontSize: '4.5em' }}> Publications </Title>
            <ArticleDisplay pos={1} img={JEE} title="Journal of Ecological Engineering" link="http://www.jeeng.net/">
              publishes scientific articles concerning i.a. wastewater treatment, elimination of air pollution, protection and reclamation of degraded soils, management of industrial waste, use of renewable energy sources. Since 2013 the journal has posted over 1000 articles and is located in the Q2 rank quarter according to the Scopus database. The journal’s scientific auspices are held by the Polish Society of Ecological Engineering. The articles are published in open access mode on the Creative Commons license (CC-BY 4.0).
            </ArticleDisplay>
            <ArticleDisplay pos={0} img={EEET} title="Ecological Engineering & Environmental Technology" link="http://www.ecoeet.com/">
              is an English-language continuation of the Polish-language scientific journal Inżynieria Ekologiczna, that has been published since 2000. It concerns wide-ranging issues of eco-technology in terms of protection and reclamation of the na-tural environment. Since 2020 the journal has been included in the Scopus database. The journal’s scientific auspices are held by the Polish Society of Ecological Engineering. The articles are published in open access mode on the Creative Commons license (CC-BY 4.0).
            </ArticleDisplay>
            <ArticleDisplay pos={1} img={ASTRJ} title="Advances in Science and Technology Research Journal" link="http://www.astrj.com/">
              Advances in Science and Technology Research Journal (ASTRJ) is an international scientific journal publishing articles concerning mechanical engineering, material engineering, production technology, modeling and computational simulation in engineering and many other topics of technology. The journal’s scientific auspices are held by the Polish Academy of Sciences, Department in Lublin. The ASTRJ is published as a quarterly in an electronic version, in open access mode on the Creative Commons license (CC-BY 4.0).
            </ArticleDisplay>
          </div>
          <div id="Contact">
            <ContactsPanel title="Contact"> 
              <Title style={{fontSize: '2.2em'}}> Office </Title>
              <p>
                email: <a href="mailto: office@wngb.pl">office@wngb.pl </a>
              </p>
              <Title style={{fontSize: '2.2em'}}> Payment information </Title>
              <p>
              Bank name: mBank SA (formerly: BRE Bank) <br/>
              SWIFT/BIC: BREXPLPWMBK <br/>
              IBAN: <br/>
              PL 36 1140 2004 0000 3512 0415 0777  (for Euro only) <br/>
              or: <br/>
              PL 60 1140 2004 0000 3312 0414 3459  (for Dollar only) 
              </p>
              <Title style={{fontSize: '2.2em'}}> Address </Title>
              <p>
                Ul. Lazurowa 26/1<br/>
                20-781 Lublin, Poland<br/>
              </p>
              <Title style={{fontSize: '2.2em'}}> NIP / VAT ID </Title>
              <p>
                PL 7120313047
              </p>
            </ContactsPanel>
          </div>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}> ❤️ Created with love by Krzysztof Borowski ©2021</Footer>
    </Layout>
  );
}

export default App;
